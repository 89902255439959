import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
    Collapse,
    Grid2,
    Alert,
    CircularProgress,
} from "@mui/material";
import { compareHireStatements } from "../services/hireService"
import HireStatementResult from "./HireStatementResult"
import Feedback from "./Feedback"
import DocumentUploader from './DocumentUploader'
import { UserContext } from '../context/UserContext';

const HireStatementComparison = () => {
    const [filesSection1, setFilesSection1] = useState([]);
    const [filesSection2, setFilesSection2] = useState([]);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [useOCR, setUseOCR] = useState(true);
    const [useVision, setUseVision] = useState(true);
    const [useContentCache, setUseContentCache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const { user } = useContext(UserContext);

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleCompare = async () => {
        if (filesSection1.length === 0 || filesSection2.length === 0) {
            alert("Please upload a file in both sections before comparing.");
            return;
        }
        console.log("Comparing hire statements...", {
            filesSection1,
            filesSection2,
            options: {
                useOCR,
                useVision,
                useContentCache,
            },
        });
        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            const comparisonResult = await compareHireStatements(filesSection1[0], filesSection2[0],
                useOCR,
                useVision,
                useContentCache,
                user.orgId,
            );
            setResult(comparisonResult);
            console.log(comparisonResult);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h5" sx={{ mt: 2 }}>
                Hire Statement Comparison
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                Upload PDF Files
            </Typography>

            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* Grid Layout for Upload Sections */}
            <Grid2 container spacing={2} sx={{ mt: 3 }}>
                {/* Section 1 for File Upload */}
                <Grid2 size={6} key={1}>
                    <DocumentUploader files={filesSection1} setFiles={setFilesSection1} title={"Upload first hire statement PDF"} setResult={setResult}></DocumentUploader>
                </Grid2>

                {/* Section 2 for File Upload */}
                <Grid2 size={6} key={2}>
                    <DocumentUploader files={filesSection2} setFiles={setFilesSection2} title={"Upload second hire statement PDF"} setResult={setResult}></DocumentUploader>
                </Grid2>
            </Grid2>

            {user.isAdmin && (
                <>
                    {/* Advanced Options Toggle */}
                    <Button
                        variant="outlined"
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>

                    {/* Advanced Options Section */}
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useOCR}
                                        onChange={(e) => setUseOCR(e.target.checked)}
                                    />
                                }
                                label="Use OCR for Hire"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useVision}
                                        onChange={(e) => setUseVision(e.target.checked)}
                                    />
                                }
                                label="Use Vision for Hire"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useContentCache}
                                        onChange={(e) => setUseContentCache(e.target.checked)}
                                    />
                                }
                                label="Use Cache for Hire"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Compare Button */}
            <Button onClick={handleCompare} variant="contained" sx={{ mt: 3 }} disabled={filesSection1.length === 0 || filesSection2.length === 0} >
                Compare Hire Statements
            </Button>

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <>
                    <HireStatementResult comparisonResult={result}></HireStatementResult>
                    <Feedback requestId={result.request_id} type={"Hire"}></Feedback>
                </>

            )}
        </Box>
    );
};

export default HireStatementComparison;
