import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Typography,
    Modal,
    Paper,
    useTheme,
    IconButton,
    LinearProgress,
    Fade,
    Grid2,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { compareMLBLObject, compareMR2MRObject } from "../services/mrService"
import MatchingDocumentPreview from "./MatchingDocumentPreview";
import { UserContext } from '../context/UserContext';
import PropTypes from 'prop-types';

const MatchModal = ({ open, onClose, matchData, mrFiles, blFiles, onMatchConfirmed, comparedWithBl }) => {
    MatchModal.propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        matchData: PropTypes.object,
        mrFiles: PropTypes.object,
        blFiles: PropTypes.object,
        onMatchConfirmed: PropTypes.func,
        comparedWithBl: PropTypes.bool,
    };
    const theme = useTheme();
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const [transition, setTransition] = useState(true);
    const [blTransition, setBlTransition] = useState(true);
    const { user } = useContext(UserContext);

    const pages = Object.keys(matchData);
    const totalSteps = pages.length;

    const progress = ((currentPageIndex + 1) / totalSteps) * 100;

    const handleNextPage = () => {
        if (currentPageIndex < pages.length - 1) {
            setTransition(false);
            setTimeout(() => {
                setCurrentPageIndex(currentPageIndex + 1);
                setCurrentMatchIndex(0);
                setTransition(true);
                setBlTransition(true);
            }, 300);
        } else {
            onClose();
        }
    };

    const handlePreviousMatch = () => {
        if (currentMatchIndex > 0) {
            setBlTransition(false);
            setTimeout(() => {
                setCurrentMatchIndex(currentMatchIndex - 1);
                setBlTransition(true);
            }, 300);
        } else if (currentPageIndex > 0) {
            setBlTransition(false);
            setTimeout(() => {
                setCurrentPageIndex(currentPageIndex - 1);
                const previousMatches = matchData[pages[currentPageIndex - 1]];
                setCurrentMatchIndex(previousMatches.length - 1);
                setBlTransition(true);
            }, 300);
        }
    };

    const handleNextMatch = () => {
        const currentMatches = matchData[pages[currentPageIndex]];
        if (currentMatchIndex < currentMatches.length - 1) {
            setBlTransition(false);
            setTimeout(() => {
                setCurrentMatchIndex(currentMatchIndex + 1);
                setBlTransition(true);
            }, 300);
        } else {
            handleNextPage();
        }
    };

    const handleConfirmMatch = async () => {
        handleNextPage();
        if(comparedWithBl){
            try {
                const response = await compareMLBLObject(
                    findMrFile(currentPageId),
                    findBlFile(currentMatch.doc_id),
                    user.orgId,
                    true
                );
    
                console.log(response);
                // Update compiled responses in the parent
                onMatchConfirmed(response);
    
            } catch (err) {
                console.log(err.message);
            }
        }
        else{
            try {
                const response = await compareMR2MRObject(
                    findMrFile(currentPageId),
                    findBlFile(currentMatch.doc_id),
                    user.orgId,
                    true
                );
    
                console.log(response);
                // Update compiled responses in the parent
                onMatchConfirmed(response);
    
            } catch (err) {
                console.log(err.message);
            }
        }
    };

    const findMrFile = (docId) => {
        return mrFiles.find(file => file.doc_id === docId);
    };

    const findBlFile = (docId) => {
        return blFiles.find(file => file.doc_id === docId);
    };

    const getPercentageStyle = (percentage) => {
        if (percentage > 0.75) {
            return { color: "green" };
        } else if (percentage > 0.5) {
            return { color: "orange" };
        } else {
            return { color: "red" };
        }
    };

    const handleNoMatch = () => {
        handleNextPage();
    };

    const currentPageId = pages[currentPageIndex];
    const currentMatches = matchData[currentPageId];
    const currentMatch = currentMatches[currentMatchIndex];

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="match-modal-title">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 4,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Paper
                    sx={{
                        padding: 4,
                        maxWidth: "80%",
                        maxHeight: "85vh",
                        overflowY: "auto",
                        position: "relative",
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <IconButton
                        sx={{ position: "absolute", top: 16, right: 16 }}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>

                    <Typography variant="h6" id="match-modal-title" gutterBottom>
                        Match Pages
                    </Typography>

                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{ mb: 2 }}
                    />

                    <Fade in={transition} timeout={300}>
                        <Box>
                            <Typography variant="h5" sx={{ p: 2 }}>
                                Match Score:{' '}
                                <Typography variant="h5" component="span" sx={getPercentageStyle(currentMatch.score)}>
                                    {(currentMatch.score * 100).toFixed(2)}%
                                </Typography>
                            </Typography>
                            <Grid2 container spacing={2}>
                                <Grid2 item size={{xs:6}}>
                                    <MatchingDocumentPreview doc1={findMrFile(currentPageId)} doc2={findBlFile(currentMatch.doc_id)} title={"MR Document"} disablePaper={true} />
                                </Grid2>
                                <Grid2 item size={{xs:6}}>
                                    <Fade in={blTransition} timeout={300}>
                                        <Box>
                                            <MatchingDocumentPreview doc2={findMrFile(currentPageId)} doc1={findBlFile(currentMatch.doc_id)} title={"BL Document"} disablePaper={false}/>
                                        </Box>
                                    </Fade>
                                </Grid2>
                            </Grid2>
                        </Box>
                    </Fade>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmMatch}
                        >
                            Confirm Match
                        </Button>
                        <Button variant="outlined" onClick={handlePreviousMatch}>
                            Back BL
                        </Button>
                        <Button variant="outlined" onClick={handleNextMatch}>
                            Next BL
                        </Button>
                        <Button variant="text" color="error" onClick={handleNoMatch}>
                            No Match
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
};

export default MatchModal;