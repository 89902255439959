import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Button,
    Collapse,
    Divider,
    Grid2,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MatchModal from "./MatchModal";
import { MRStaticDetail, MRAmendmentForm } from "./MatesReceiptsResult";
import PropTypes from "prop-types";

const MultipleMatching = ({ response, comparedWithBl }) => {
    const [result, setResult] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [compiledResponses, setCompiledResponses] = useState([]);
    const [expandedPanels, setExpandedPanels] = useState({});

    useEffect(() => {
        setResult(response);
    }, [response]);

    if (!result) return null;

    const { matches, total_time, pdf_storage_time } = result;

    const handleMatchConfirmation = (response) => {
        setCompiledResponses((prevResponses) => [...prevResponses, response]);
    };

    const togglePanel = (index) => {
        setExpandedPanels((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const renderMatchModal = () => (
        <MatchModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            mrFiles={comparedWithBl ? result.mates_receipts : result.mates_receipt1}
            blFiles={comparedWithBl ? result.bills_of_lading : result.mates_receipt2}
            matchData={matches.matches}
            onMatchConfirmed={handleMatchConfirmation}
            comparedWithBl={comparedWithBl}
        />
    );

    const getTextColor = (changeToBLNeeded) => {
        if(comparedWithBl){
            if(changeToBLNeeded) {
                return 'red';
            }
            else{
                return 'green';
            }
        }
        else{
            return 'inherit';
        }
    }

    const renderPanelContent = (response, index) => {
        const { comparison_result: comparison } = response;
        const titles = comparedWithBl
            ? ["Mates Receipt", "Bill of Lading"]
            : ["Original Mates Receipt", "Updated Mates Receipt"];
        const doc1 = comparedWithBl ? comparison.mates_receipt : comparison.original_MR;
        const doc2 = comparedWithBl ? comparison.bl : comparison.updated_MR;
        const comparisonData = comparedWithBl ? comparison.mr_bl_comparison : comparison.mr_comparison;
        const changeToBLNeeded = comparedWithBl ? comparison.mr_bl_comparison.bill_of_lading_needs_updating : false;
    
        return (
            <>
            <Button
                variant="text"
                fullWidth
                onClick={() => togglePanel(index)}
                sx={{
                    justifyContent: "flex-start",
                    typography: "h5",
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    color: getTextColor(changeToBLNeeded),
                }}
            >
                Shipping Mark:{" "}
                {comparedWithBl
                    ? response.comparison_result.mates_receipt.shipping_marks
                    : response.comparison_result.original_MR.shipping_marks}
                <ExpandMoreIcon
                    sx={{
                        ml: "auto",
                        transition: "transform 0.3s",
                        transform: expandedPanels[index] ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                />
            </Button>
            <Collapse in={expandedPanels[index]}>
                <MRStaticDetail
                    key={`static-detail-${index}`}
                    doc1={doc1}
                    doc2={doc2}
                    comparison={comparisonData}
                    titles={titles}
                />
                {!comparedWithBl && (
                    <MRAmendmentForm
                        doc1={doc1}
                        doc2={doc2}
                        comparison={comparisonData}
                        requestId={response.request_id}
                    />
                )}
            </Collapse>
            </>
        );
    };
    
    return (
        <Box p={3}>
            {renderMatchModal()}

            <Button
                variant="text"
                fullWidth
                onClick={() => setExpanded(!expanded)}
                sx={{ mt: 3, justifyContent: "flex-start" }}
            >
                {`Response time: ${total_time.toFixed(2)} seconds`}
            </Button>

            <Collapse in={expanded}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>Total Time</Typography>
                        <Typography sx={{ mb: 2 }} variant="h4">
                            {total_time.toFixed(2)} seconds
                        </Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography sx={{ mb: 1 }}>PDF Storage Time</Typography>
                        <Typography variant="h4">
                            {pdf_storage_time.toFixed(2)} seconds
                        </Typography>
                    </Grid2>
                </Grid2>
            </Collapse>

            <Button variant="contained" onClick={() => setIsModalOpen(true)}>
                View Matches
            </Button>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h4" sx={{ my: 2 }}>
                All Confirmed Comparisons
            </Typography>

            {compiledResponses.map((response, index) => (
                <Box key={`panel-${index}`} mb={2}>
                    {renderPanelContent(response, index)}
                </Box>
            ))}
        </Box>
    );
};

MultipleMatching.propTypes = {
    response: PropTypes.object.isRequired,
    comparedWithBl: PropTypes.bool,
};

export default MultipleMatching;
