import React, { useContext, useState, useEffect } from 'react';
import { Box, Tabs, Tab, Switch, Typography, FormGroup, FormControlLabel, Button } from '@mui/material';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom'; // Import Router hooks and components
import CargoComparison from './CargoComparison';
import HireStatementComparison from './HireStatementComparison';
import LOIGeneration from './LOIGeneration';
import MatesReceiptsComparison from './MatesReceiptsComparison';
import LOI2LOI from './LOI2LOI';
import Recap2Instructions from './Recap2Instructions';
import { useTheme } from '../context/ThemeContext'; // Import the useTheme hook
import { UserContext } from '../context/UserContext'; // Import UserContext
import SOFComparison from './SOFComparison';
import BLMRComparison from './BLMRComparison';

const Dashboard = () => {
    const { isDarkMode, toggleTheme } = useTheme(); // Get theme state and toggle function
    const { clearUser } = useContext(UserContext); // Access clearUser from context
    const navigate = useNavigate(); // Hook for navigation
    const location = useLocation(); // Hook for getting the current location

    const [selectedTab, setSelectedTab] = useState(0); // State for selected tab

    // Function to handle tab changes
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Map current location to the correct tab index
    useEffect(() => {
        if (location.pathname.includes('cargo-comparison')) {
            setSelectedTab(0);
        } else if (location.pathname.includes('mr-comparison')) {
            setSelectedTab(1);
        } else if (location.pathname.includes('mr-bl-comparison')) {
            setSelectedTab(2);
        } else if (location.pathname.includes('hire-comparison')) {
            setSelectedTab(3);
        } else if (location.pathname.includes('sof-comparison')) {
            setSelectedTab(4);
        } else if (location.pathname.includes('bl-to-loi')) {
            setSelectedTab(5);
        } else if (location.pathname.includes('loi-to-loi')) {
            setSelectedTab(6);
        } else if (location.pathname.includes('recap-to-instructions')) {
            setSelectedTab(7);
        }
    }, [location]);

    const handleLogout = () => {
        clearUser(); // Clear the user context
        navigate('/'); // Navigate back to login after logging out
    };

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">Welcome to Shipster Tools Dashboard</Typography>
                <Box display="flex" alignItems="center">
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={isDarkMode} onChange={toggleTheme} />} label="Dark Mode" />
                    </FormGroup>
                    <Button variant="contained" color="secondary" onClick={handleLogout} sx={{ ml: 2 }}>
                        Logout
                    </Button>
                </Box>
            </Box>

            {/* Render tabs with Links */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
            >
                <Tab label="Cargo Comparison" component={Link} to="cargo-comparison" />
                <Tab label="MR Comparison" component={Link} to="mr-comparison" />
                <Tab label="MR to BL Comparison" component={Link} to="mr-bl-comparison" />
                <Tab label="Hire Statement Comparison" component={Link} to="hire-comparison" />
                <Tab label="SOF Comparison" component={Link} to="sof-comparison" />
                <Tab label="BL to LOI" component={Link} to="bl-to-loi" />
                <Tab label="LOI to LOI" component={Link} to="loi-to-loi" />
                <Tab label="Recap to Instructions" component={Link} to="recap-to-instructions" />
            </Tabs>

            {/* Define Routes for the different dashboard components */}
            <Routes>
                <Route path="cargo-comparison" element={<CargoComparison />} />
                <Route path="mr-comparison" element={<MatesReceiptsComparison />} />
                <Route path="mr-bl-comparison" element={<BLMRComparison />} />
                <Route path="hire-comparison" element={<HireStatementComparison />} />
                <Route path="sof-comparison" element={<SOFComparison />} />
                <Route path="bl-to-loi" element={<LOIGeneration />} />
                <Route path="loi-to-loi" element={<LOI2LOI />} />
                <Route path="recap-to-instructions" element={<Recap2Instructions />} />
            </Routes>
        </Box>
    );
};

export default Dashboard;
