import { getDocument } from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { PDFDocument } from "pdf-lib";
import { Document, Packer } from 'docx';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import mammoth from "mammoth";

// Set the worker for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const toSnakeCase = (str) => {
  if (!str) return "";
  const regex =
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;
  return str
    .match(regex)
    .map((x) => x.toLowerCase())
    .join("_");
};

export const toTitleCase = (s) => {
  if (!s) return "";
  else {
    return s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
  }
}

export const spliceFile = async (uploadedFile, startPage, endPage) => {
  // Extract the original file name without extension
  const originalFileName = uploadedFile.name.replace(/\.[^/.]+$/, "");

  // Check the file type
  const fileType = uploadedFile.type;

  if (fileType === "application/pdf") {
    // Handle PDF documents
    const newFileName = `${originalFileName}_spliced.pdf`;

    // Load and splice the PDF document
    const fileArrayBuffer = await uploadedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(fileArrayBuffer);
    const splicedPdfDoc = await PDFDocument.create();

    // Loop through the specified pages and add them to the new PDF
    for (let i = startPage - 1; i < endPage; i++) {
      const [page] = await splicedPdfDoc.copyPages(pdfDoc, [i]);
      splicedPdfDoc.addPage(page);
    }

    // Save the spliced PDF as a Blob
    const splicedPdfBytes = await splicedPdfDoc.save();
    return new File([splicedPdfBytes], newFileName, { type: "application/pdf" });

  } else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    // Handle Word documents
    const newFileName = `${originalFileName}_spliced.docx`;

    // Read the Word file as an array buffer
    const fileArrayBuffer = await uploadedFile.arrayBuffer();

    // Extract the text content from the Word file using mammoth.js
    const result = await mammoth.extractRawText({ arrayBuffer: fileArrayBuffer });
    const paragraphs = result.value.split('\n');

    // Extract the specified paragraphs
    const splicedContent = paragraphs.slice(startPage - 1, endPage).map(text => ({
      children: [
        {
          text,
        },
      ],
    }));

    // Create a new Word document
    const newDoc = new Document({
      sections: [
        {
          properties: {},
          children: splicedContent,
        },
      ],
    });

    // Generate the spliced Word document as a Blob
    const splicedDocBlob = await Packer.toBlob(newDoc);
    return new File([splicedDocBlob], newFileName, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
  } else {
    throw new Error("Unsupported file type. Only PDF and Word documents are supported.");
  }
};


export const getNumberOfPages = async (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async function (e) {
      const arrayBuffer = e.target.result;

      try {
        if (file.type === "application/pdf") {
          // Get number of pages for PDF
          const pdf = await getDocument({ data: arrayBuffer }).promise;
          if (!pdf || typeof pdf.numPages !== 'number') {
            throw new Error("Failed to load PDF or PDF has no pages.");
          }
          resolve(pdf.numPages);

        } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          // Approximate page count for .docx by word count
          const { value } = await mammoth.extractRawText({ arrayBuffer });
          const wordsPerPage = 500; // Approximation
          const pageCount = Math.ceil(value.split(/\s+/).length / wordsPerPage);
          resolve(pageCount);

        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel") {
          // Get number of sheets for Excel file
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetCount = workbook.SheetNames.length;
          resolve(sheetCount);

        } else {
          throw new Error("Unsupported file type");
        }
      } catch (error) {
        console.error("Error processing file:", error);
        reject(error);
      }
    };

    reader.onerror = function (e) {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });

};

// Copy table data to clipboard
export const copyToClipboard = (table) => {
  const headers = '#|Date|Event|From Time|To Time|Difference\n'
  const textData = table.map((row, index) =>
    `${index + 1}|${row.date}|${row.event}|${row.from_time}|${row.to_time}|${row.type_of_difference}`
  ).join('\n');
  navigator.clipboard.writeText(headers + textData).then(() => {
    alert('Table copied to clipboard!');
  }).catch(err => {
    console.error('Failed to copy:', err);
  });
};

export const copyToClipboardDetail = (table) => {
  const headers = '#|Date|Agent Event|Master Event|Agent From Time|Agent To Time|Master From Time|Master To Time|Difference\n'
  const textData = table.map((row, index) =>
    `${index + 1}|${row.date}|${row.agent_event}|${row.captain_event}|${row.agent_from_time}|${row.agent_to_time}|${row.captain_from_time}|${row.captain_to_time}|${row.type_of_difference}`
  ).join('\n');
  navigator.clipboard.writeText(headers + textData).then(() => {
    alert('Table copied to clipboard!');
  }).catch(err => {
    console.error('Failed to copy:', err);
  });
};

// Export table data to Excel
export const exportToExcel = (table) => {
  // Prepare the data for Excel
  const worksheetData = [
    ['#', 'Date', 'Event', 'From Time', 'To Time', 'Difference'],
    ...table.map((row, index) => [
      index + 1, row.date, row.event, row.from_time, row.to_time, row.type_of_difference
    ])
  ];

  // Create a worksheet and a workbook
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Comparison Result");

  // Export as Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, 'comparison_result.xlsx');

};

// Export table data to Excel
export const exportToExcelDetail = (table) => {
  // Prepare the data for Excel
  const worksheetData = [
    ['#', 'Date', 'Agent Event', 'Master Event', 'Agent From Time', 'Agent To Time', 'Master From Time', 'Master To Time', 'Difference'],
    ...table.map((row, index) => [
      index + 1, row.date, row.agent_event, row.captain_event, row.agent_from_time, row.agent_to_time, row.captain_from_time, row.captain_to_time, row.difference_description
    ])
  ];

  // Create a worksheet and a workbook
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Comparison Result");

  // Export as Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, 'comparison_result.xlsx');

};