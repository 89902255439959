import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
    Collapse,
    Grid2,
    Alert,
    CircularProgress,
} from "@mui/material";
import { extractaMRBlMatch } from "../services/mrService";
import Feedback from "./Feedback"
import { UserContext } from '../context/UserContext';
import DocumentUploader from './DocumentUploader'
import MultipleMatching from './MultipleMatching'

const BLMRComparison = () => {
    const [billOfLading, setBillOfLading] = useState([]);
    const [matesReceipts, setMatesReceipts] = useState([]);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [useBLOCR, setUseBLOCR] = useState(true);
    const [useBLVision, setUseBLVision] = useState(true);
    const [useMROCR, setUseMROCR] = useState(true);
    const [useMRVision, setUseMRVision] = useState(true);
    const [useContentCache, setUseContentCache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const { user } = useContext(UserContext);

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleCompare = async () => {
        if (matesReceipts.length === 0 || billOfLading.length === 0) {
            alert("Please upload file(s) in both sections before comparing.");
            return;
        }
        console.log("Comparing bill of lading to mates receipt...", {
            matesReceipts,
            billOfLading,
            options: {
                useMROCR,
                useMRVision,
                useBLOCR,
                useBLVision,
                useContentCache,
            },
        });

        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            const response = await extractaMRBlMatch(billOfLading[0], matesReceipts[0], {
                useMROCR,
                useMRVision,
                useBLOCR,
                useBLVision,
                useContentCache,
                organisationId: user.orgId, // you can set a default organisation ID if needed
            });
            setResult(response); // Store the result
            console.log(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h5" sx={{ mt: 2 }}>
                MR to BL Comparison
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                Upload PDF Files
            </Typography>

            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* Grid Layout for Upload Sections */}
            <Grid2 container spacing={2} sx={{ mt: 3 }}>
                <Grid2 size={6} key={1}>
                    <DocumentUploader files={matesReceipts} setFiles={setMatesReceipts} title={"Upload Mates Receipt PDF"} setResult={setResult}></DocumentUploader>
                </Grid2>
                <Grid2 size={6} key={2}>
                    <DocumentUploader files={billOfLading} setFiles={setBillOfLading} title={"Upload Bill of Lading PDF"} setResult={setResult}></DocumentUploader>
                </Grid2>
            </Grid2>

            {user.isAdmin && (
                <>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useBLOCR}
                                        onChange={(e) => setUseBLOCR(e.target.checked)}
                                    />
                                }
                                label="Use BL OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useBLVision}
                                        onChange={(e) => setUseBLVision(e.target.checked)}
                                    />
                                }
                                label="Use BL Vision"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useMROCR}
                                        onChange={(e) => setUseMROCR(e.target.checked)}
                                    />
                                }
                                label="Use MR OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useMRVision}
                                        onChange={(e) => setUseMRVision(e.target.checked)}
                                    />
                                }
                                label="Use MR Vision"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useContentCache}
                                        onChange={(e) => setUseContentCache(e.target.checked)}
                                    />
                                }
                                label="Use Cache"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Compare Button */}
            <Button
                onClick={handleCompare}
                variant="contained"
                sx={{ mt: 3 }}
                disabled={matesReceipts.length === 0 || billOfLading.length === 0}
            >
                Compare MR to BL
            </Button>

            {result && (
                <Feedback requestId={result.request_id} type={"BLMR"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <MultipleMatching response={result} comparedWithBl={true}></MultipleMatching>
            )}
        </Box>
    );
};

export default BLMRComparison;
