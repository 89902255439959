import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  Collapse,
  Grid2,
  Alert,
  CircularProgress,
} from "@mui/material";
import { compareMatesReceipts, extractMRandMatch } from "../services/mrService";
import MatesReceiptsResult from "./MatesReceiptsResult";
import Feedback from "./Feedback";
import DocumentUploader from "./DocumentUploader";
import MultipleMatching  from "./MultipleMatching";
import { getNumberOfPages } from "../helpers";
import { UserContext } from "../context/UserContext";

const MatesReceiptsComparison = () => {
  const [filesSection1, setFilesSection1] = useState([]);
  const [filesSection2, setFilesSection2] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [useComparisonCache, setUseComparisonCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [originalPdfPageCount, setOriginalPdfPageCount] = useState(1); // Number of pages in original PDF
  const [updatedPdfPageCount, setUpdatedPdfPageCount] = useState(1); // Number of pages in updated PDF
  const { user } = useContext(UserContext);

// Call getNumberOfPages when PDFs are selected and set these counts accordingly
useEffect(() => {
  if (filesSection1.length > 0) {
    getNumberOfPages(filesSection1[0]).then((pageCount) =>
      setOriginalPdfPageCount(pageCount)
    );
  }
  if (filesSection2.length > 0) {
    getNumberOfPages(filesSection2[0]).then((pageCount) =>
      setUpdatedPdfPageCount(pageCount)
    );
  }
}, [filesSection1, filesSection2]);

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleCompare = async () => {
    if (filesSection1.length === 0 || filesSection2.length === 0) {
      alert("Please upload a file in both sections before comparing.");
      return;
    }
    console.log("Comparing Mates Receipts...", {
      filesSection1,
      filesSection2,
      options: {
        useOCR,
        useVision,
        useContentCache,
        useComparisonCache,
      },
    });

    setLoading(true);
    setError(null); // Clear any previous errors
    setResult(null); // Clear previous result
    console.log(originalPdfPageCount);
    console.log(updatedPdfPageCount);

    if(originalPdfPageCount > 1 && updatedPdfPageCount > 1){
      try {
        const matches = await extractMRandMatch(
          filesSection1[0],
          filesSection2[0],
          {
            useOCR,
            useVision,
            useComparisonCache,
            organisationId: user.orgId, // you can set a default organisation ID if needed
          }
        );
        setResult(matches);
        console.log(matches);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    else{
      try {
        const comparisonResult = await compareMatesReceipts(
          filesSection1[0],
          filesSection2[0],
          {
            useOCR,
            useVision,
            useContentCache,
            useComparisonCache,
            organisationId: user.orgId, // you can set a default organisation ID if needed
          }
        );
        setResult(comparisonResult);
        console.log(comparisonResult);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Mates Receipts Comparison
      </Typography>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Upload PDF Files
      </Typography>

      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}

      {/* Grid Layout for Upload Sections */}
      <Grid2 container spacing={2} sx={{ mt: 3 }}>
        {/* Section 1 for File Upload */}
        <Grid2 size={6} key={1}>
          <DocumentUploader
            files={filesSection1}
            setFiles={setFilesSection1}
            title={"Upload first Mates Receipt PDF"}
            setResult={setResult}
          ></DocumentUploader>
        </Grid2>

        {/* Section 2 for File Upload */}
        <Grid2 size={6} key={2}>
          <DocumentUploader
            files={filesSection2}
            setFiles={setFilesSection2}
            title={"Upload second Mates Receipt PDF"}
            setResult={setResult}
          ></DocumentUploader>
        </Grid2>
      </Grid2>

      {/* Advanced Options Toggle */}
      <Button
        variant="outlined"
        fullWidth
        onClick={handleAdvancedOptionsClick}
        sx={{ mt: 3 }}
      >
        {showAdvancedOptions
          ? "Hide Advanced Options"
          : "Show Advanced Options"}
      </Button>

      {/* Advanced Options Section */}
      <Collapse in={showAdvancedOptions}>
        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
          {user.isAdmin && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useOCR}
                    onChange={(e) => setUseOCR(e.target.checked)}
                  />
                }
                label="Use OCR"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useVision}
                    onChange={(e) => setUseVision(e.target.checked)}
                  />
                }
                label="Use Vision"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useContentCache}
                    onChange={(e) => setUseContentCache(e.target.checked)}
                  />
                }
                label="Use Content Cache"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useComparisonCache}
                    onChange={(e) => setUseComparisonCache(e.target.checked)}
                  />
                }
                label="Use Comparison Cache"
              />
            </>
          )}
        </Box>
      </Collapse>

      {/* Compare Button */}
      <Button
        onClick={handleCompare}
        variant="contained"
        sx={{ mt: 3 }}
        disabled={filesSection1.length === 0 || filesSection2.length === 0}
      >
        Compare Mates Receipts
      </Button>

      {result && (
        <Feedback requestId={result.request_id} type={"MR"}></Feedback>
      )}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {(result && originalPdfPageCount === 1 && updatedPdfPageCount === 1) && <MatesReceiptsResult response={result}></MatesReceiptsResult>}
      {(result && originalPdfPageCount > 1 && updatedPdfPageCount > 1) && <MultipleMatching response={result} comparedWithBl={false}></MultipleMatching>}
    </Box>
  );
};

export default MatesReceiptsComparison;
