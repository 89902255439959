import React, { useState, useContext, useEffect } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    Box,
    Collapse,
    Grid2,
    Alert,
    CircularProgress,
    Select,
    MenuItem,
} from "@mui/material";
import { extractDetailedLoiFromPdf } from "../services/bl2loiService";
import { getNumberOfPages, spliceFile } from "../helpers"
import LOIGenerationResult from "./LOIGenerationResult"
import Feedback from "./Feedback"
import DocumentUploader from './DocumentUploader'
import { UserContext } from '../context/UserContext';
import { requesterCompanyOptions } from "../config/loginDetails";

const LOIGeneration = () => {
    const { user } = useContext(UserContext);
    // Filter letterhead options based on user organization
    const availableRequesterOptions = requesterCompanyOptions.filter(option => option.organisation === user.orgId);

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [useOCR, setUseOCR] = useState(true);
    const [useVision, setUseVision] = useState(true);
    const [useContentCache, setUseContentCache] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [requestorCompany, setRequestorCompany] = useState(availableRequesterOptions[0]?.label);
    const [requestorAddress] = useState(availableRequesterOptions[0]?.address);
    const [requestorDesignation, setRequestorDesignation] = useState("");
    const [requestorName, setRequestorName] = useState("John Doe");
    const [pageCount, setPageCount] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(pageCount ? pageCount : 1);

    useEffect(() => {
        if (uploadedFile.length > 0) {
            getNumberOfPages(uploadedFile[0]).then((pageCount) => { setPageCount(pageCount); setEndPage(pageCount) });
        }
    }, [uploadedFile]);

    const handleAdvancedOptionsClick = () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    };

    const handleGeneration = async () => {
        if (uploadedFile.length === 0) {
            alert("Please upload a file before generating");
            return;
        }
        console.log("Generating LOI from bill of lading...", {
            uploadedFile,
            requester: {
                requestorCompany,
                requestorName,
            },
            options: {
                useOCR,
                useVision,
                useContentCache,
            },
        });

        setLoading(true);
        setError(null); // Clear any previous errors
        setResult(null); // Clear previous result

        try {
            // Temporarily chose not to splice word docuemnts 
            let splicedFile = uploadedFile[0];
            if (uploadedFile[0].type === "application/pdf"){
                splicedFile = await spliceFile(uploadedFile[0], startPage, endPage);
            }

            const comparisonResult = await extractDetailedLoiFromPdf(splicedFile, requestorName, requestorCompany, {
                useOCR,
                useVision,
                useContentCache,
                organisationId: user.orgId, // you can set a default organisation ID if needed
            });
            setResult(comparisonResult);
            console.log(comparisonResult);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box p={3}>
            <Typography variant="h5" sx={{ mt: 2 }}>
                LOI Generation
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                Upload Bill of Lading PDF
            </Typography>

            {error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {error}
                </Alert>
            )}

            {/* PDF Upload Section */}
            <DocumentUploader files={uploadedFile} setFiles={setUploadedFile} title={"Upload Bill of Lading PDF"} setResult={setResult}></DocumentUploader>
            {/* Input text fields */}
            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Page Range</Typography>
                <Grid2 container spacing={2}>
                    <Grid2 size={6}>
                        <TextField
                            label="Start Page"
                            type="number"
                            value={startPage}
                            onChange={(e) => {
                                const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                setStartPage(newValue); // Update state only if input is within range or empty
                            }}
                            slotProps={{
                                input: {
                                    min: 1,
                                    max: pageCount,
                                    onInput: (e) => {
                                        const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                        e.target.value = newValue;
                                    },
                                },
                            }}
                            disabled={pageCount === 1}
                            sx={{ mt: 2, width: "100%" }}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <TextField
                            label="End Page"
                            type="number"
                            value={endPage}
                            onChange={(e) => {
                                const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                setEndPage(newValue);
                            }}
                            slotProps={{
                                input: {
                                    min: 1,
                                    max: pageCount,
                                    onInput: (e) => {
                                        const newValue = e.target.value === "" ? "" : Math.min(Math.max(1, Number(e.target.value)), pageCount);
                                        e.target.value = newValue;
                                    },
                                },
                            }}
                            disabled={pageCount === 1}
                            sx={{ mt: 2, width: "100%" }}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={2} sx={{ mt: 3 }}>
                    <Grid2 size={4}>
                        <Typography>Requestor Name (Company)</Typography>
                        <Select
                            value={requestorCompany}
                            onChange={(e) => setRequestorCompany(e.target.value)}
                            fullWidth
                            variant="outlined"
                        >
                            {availableRequesterOptions.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid2>
                    <Grid2 size={4}>
                        <Typography>Requester Full Name</Typography>
                        <TextField
                            id="requester-name"
                            variant="outlined"
                            fullWidth
                            value={requestorName}
                            onChange={(e) => setRequestorName(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Typography>Requestor Title</Typography>
                        <TextField
                            id="requester-designation"
                            variant="outlined"
                            fullWidth
                            value={requestorDesignation}
                            onChange={(e) => setRequestorDesignation(e.target.value)}
                        />
                    </Grid2>
                </Grid2>
            </Box>

            {user.isAdmin && (
                <>
                    {/* Advanced Options Toggle */}
                    <Button
                        variant="outlined"
                        onClick={handleAdvancedOptionsClick}
                        sx={{ mt: 3 }}
                    >
                        {showAdvancedOptions
                            ? "Hide Advanced Options"
                            : "Show Advanced Options"}
                    </Button>

                    {/* Advanced Options Section */}
                    <Collapse in={showAdvancedOptions}>
                        <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useOCR}
                                        onChange={(e) => setUseOCR(e.target.checked)}
                                    />
                                }
                                label="Use OCR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useVision}
                                        onChange={(e) => setUseVision(e.target.checked)}
                                    />
                                }
                                label="Use Vision"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useContentCache}
                                        onChange={(e) => setUseContentCache(e.target.checked)}
                                    />
                                }
                                label="Use Cache"
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {/* Submit Button */}
            <Button
                onClick={handleGeneration}
                variant="contained"
                color="primary"
                sx={{ marginTop: "20px" }}
                disabled={uploadedFile.length === 0}
            >
                Generate LOI
            </Button>

            {result && (
                <Feedback requestId={result.request_id} type={"LOI"}></Feedback>
            )}

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                    <CircularProgress />
                </Box>
            )}

            {result && (
                <LOIGenerationResult response={result} requestorName={requestorName} requestorCompany={requestorCompany} requestorDesignation={requestorDesignation} address={requestorAddress}></LOIGenerationResult>
            )}
        </Box>
    );
};

export default LOIGeneration;
