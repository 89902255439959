import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Card,
    CardContent,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Button,
    Collapse,
    useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// eslint-disable-next-line no-unused-vars
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';

// eslint-disable-next-line no-unused-vars
const ComparisonTable = ({ children, onCopy, onSavePdf, onSaveExcel, filterOptions }) => {
    ComparisonTable.propTypes = {
        children: PropTypes.node.isRequired,
        onCopy: PropTypes.func.isRequired,
        onSavePdf: PropTypes.func.isRequired,
        onSaveExcel: PropTypes.func.isRequired,
        filterOptions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            })
        ).isRequired,
    };
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(true);
    const [filter, setFilter] = useState('all');

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    return(
        <Card
            sx={{
                backgroundColor: theme.palette.background.level1,
                color: theme.palette.text.primary,
                borderRadius: 3,
                border: '1px solid rgba(255, 255, 255, 0.2)',
                marginTop: 2,
                marginBottom: 4,
            }}
        >
            {/* Header */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Comparison Results
                </Typography>
                <IconButton onClick={toggleExpand} sx={{ color: theme.palette.text.primary }}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>

            {/* Content */}
            <Collapse in={isExpanded}>
                <CardContent>
                    {/* Filter and Action Buttons Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 2,
                        }}
                    >
                        {/* Filter Options */}
                        <RadioGroup
                            row
                            value={filter}
                            onChange={handleFilterChange}
                            sx={{ gap: 2 }}
                        >
                            {filterOptions.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio sx={{ color: theme.palette.primary.main }} />}
                                    label={<Typography sx={{ color: theme.palette.text.primary }}>{option.label}</Typography>}
                                />
                            ))}
                        </RadioGroup>

                        {/* Action Buttons */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<ContentCopyIcon />}
                                onClick={onCopy}
                                sx={{ textTransform: 'none' }}
                            >
                                Copy to Clipboard
                            </Button>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={onSavePdf}
                                sx={{ textTransform: 'none' }}
                            >
                                Save as PDF
                            </Button> */}
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<TableChartIcon />}
                                onClick={onSaveExcel}
                                sx={{ textTransform: 'none' }}
                            >
                                Save as Excel
                            </Button>
                        </Box>
                    </Box>

                    {/* Table (children) */}
                    <Box>{React.cloneElement(children, { filter })}</Box>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default ComparisonTable;
