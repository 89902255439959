import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginScreen from "./components/LoginScreen";
import Dashboard from "./components/Dashboard";
import { Worker } from "@react-pdf-viewer/core";
import { UserContext } from './context/UserContext'; // Import UserContext

function App() {
  const { user } = useContext(UserContext); // Access user from context

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Router>
        <Routes>
          {!user ? (
            <Route path="/" element={<LoginScreen />} />
          ) : (
            <>
              {/* Redirect root to dashboard */}
              <Route path="/" element={<Navigate to="/dashboard/cargo-comparison" />} />

              {/* Nested routing for dashboard */}
              <Route path="/dashboard/*" element={<Dashboard />} />
            </>
          )}
        </Routes>
      </Router>
    </Worker>
  );
}

export default App;
