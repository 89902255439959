import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { UserContext } from '../context/UserContext';
import { getFilledChristianiaVITemplate } from '../templates/ChristianiaVITemplate';
import { getFilledCenturionVITemplate } from '../templates/CenturionVITemplate';
import { getFilledFalconVITemplate } from '../templates/FalconVITemplate';
import { TextTemplate } from "./IndemnityModal"
import { sendFieldFeedback } from "../services/feedbackService";
import Modal from '@mui/material/Modal';
import { modalStyle } from '../styles/templateStyles';
import { handleSaveAsWord } from "../services/viDocxService";

const Recap2InstructionsResult = ({ response }) => {
    Recap2InstructionsResult.propTypes = {
        response: PropTypes.object.isRequired,
    };

    const { user } = useContext(UserContext);
    const [result, setResult] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [trackedChanges, setTrackedChanges] = useState({});

    useEffect(() => {
        setResult(response);
    }, [response]);

    if (!result) return null;

    const { fixure_recap_details } = result;

    const {
        cp_date: cpDateField,
        charterer: chartererField,
        laycan: laycanField,
        speed: speedField,
        cleaning: cleaningField,
        heating: heatingField,
        cargo: cargoField,
        load_port_agents: loadingAgentsField,
        load_port_agent_email: loadAgentEmailField,
        discharge_port_agents: dischargeAgentsField,
        discharge_port_agent_email: dischargeAgentEmailField,
        port_of_loading: portOfLoadingField,
        port_of_discharge: portOfDischargeField,
        previous_port: previousPortField,
        brokers_email: brokersEmailField,
        brokers: brokersField,
        discharge_rate: dischargeRateField,
        load_rate: loadRateField,
        segregation: segregationField,
        vessel: vesselField,
    } = fixure_recap_details;

    const missingValueField = { value: 'XXXX', context: null, confidence: 'LOW' };
  
    const getValue = (field) => {
        const value = field.value;
        return value || "MISSING";
    };

    const formData = {
        cpDate: getValue(cpDateField),
        charterer: getValue(chartererField),
        laycan: getValue(laycanField),
        speed: getValue(speedField),
        cleaning: getValue(cleaningField),
        heating: getValue(heatingField),
        cargo: getValue(cargoField),
        loadingAgents: getValue(loadingAgentsField),
        loadAgentEmail: getValue(loadAgentEmailField),
        dischargeAgents: getValue(dischargeAgentsField),
        dischargeAgentEmail: getValue(dischargeAgentEmailField),
        portOfLoading: getValue(portOfLoadingField),
        portOfDischarge: getValue(portOfDischargeField),
        previousPort: getValue(previousPortField),
        brokersEmail: getValue(brokersEmailField),
        brokers: getValue(brokersField),
        dischargeRate: getValue(dischargeRateField),
        loadRate: getValue(loadRateField),
        segregation: getValue(segregationField),
        vessel: getValue(vesselField),
        missingValue: getValue(missingValueField),
    };
    
    const trackChange = (field, newValue, parentField = 'fixure_recap_details') => {
        setTrackedChanges((prevChanges) => {
            const originalValue = result[parentField]?.[field]?.value; // Accessing original value
            if (originalValue !== newValue) {
                return {
                    ...prevChanges,
                    [field]: { value: newValue },
                };
            } else {
                // eslint-disable-next-line no-unused-vars
                const { [field]: _, ...remainingChanges } = prevChanges;
                return remainingChanges;
            }
        });
    };

    const sendTrackChangesToBackend = async () => {
        try {
            const backendResult = await sendFieldFeedback(user.orgId, result.requestId, trackedChanges, "VI");
            console.log(backendResult);
        } catch (error) {
            console.log(error);
        }
    };

    const updateFixtureDetails = (key, value, parentField = 'fixure_recap_details') => {
        setResult((prevResult) => {
            // Track changes before updating the field
            trackChange(key, value, parentField);

            return {
                ...prevResult,
                [parentField]: {
                    ...prevResult[parentField],
                    [key]: {
                        ...prevResult[parentField][key],
                        value,
                    },
                },
            };
        });
    };

    // Function to copy modal content to clipboard
    const handleCopyToClipboard = () => {
        const element = document.getElementById("modal-content");
        const textToCopy = element.innerText || element.textContent; // Get only the text content
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                alert("Text copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
        sendTrackChangesToBackend();
    };

    const renderTextTemplate = (field, nullContext, valueName) => {
        const value = field.value ? field.value : "MISSING";
        return (
            <TextTemplate
                value={value}
                context={field.context}
                nullContext={nullContext}
                confidence={field.confidence}
                updateFormData={(newValue) => updateFixtureDetails(valueName, newValue)}
                valueName={valueName}
            />
        )
    }

    const cpDateTemplate = renderTextTemplate(cpDateField, "CP Date", "cp_date");
    const portLoadingTemplate = renderTextTemplate(portOfLoadingField, "Port of Loading", "port_of_loading");
    const portDischargeTemplate = renderTextTemplate(portOfDischargeField, "Port of Discharge", "port_of_discharge");
    const previousPortTemplate = renderTextTemplate(previousPortField, "Starting point of vessel", "previous_port");
    const chartererTemplate = renderTextTemplate(chartererField, "Charterer Name", "charterer");
    const cargoDescriptionTemplate = renderTextTemplate(cargoField, "Cargo Description", "cargo");
    const laycanTemplate = renderTextTemplate(laycanField, "Laycan", "lycan");
    const speedTemplate = renderTextTemplate(speedField, "Speed", "speed");
    const heatingTemplate = renderTextTemplate(heatingField, "Heating", "heating");
    const cleaningTemplate = renderTextTemplate(cleaningField, "Cleaning Details", "cleaning");
    const loadingAgentsTemplate = renderTextTemplate(loadingAgentsField, "Load Port Agents", "load_port_agents");
    const loadAgentEmailTemplate = renderTextTemplate(loadAgentEmailField, "Load Port Agent Email", "load_port_agent_email");
    const dischargeAgentsTemplate = renderTextTemplate(dischargeAgentsField, "Discharge Port Agent", "discharge_port_agents");
    const dischargeAgentEmailTemplate = renderTextTemplate(dischargeAgentEmailField, "Discharge Port Agent Email", "discharge_port_agent_email");
    const brokersTemplate = renderTextTemplate(brokersField, "Brokers", "brokers");
    const brokersEmailTemplate = renderTextTemplate(brokersEmailField, "Brokers Email", "brokers_email");
    const dischargeRateTemplate = renderTextTemplate(dischargeRateField, "Discharge Rate", "discharge_rate");
    const segregationTemplate = renderTextTemplate(segregationField, "Segregation", "segregation");
    const vesselTemplate = renderTextTemplate(vesselField, "Vessel", "vessel");
    const missingValueTemplate = renderTextTemplate(missingValueField, "Data to be filled out", "");

    const onCloseFunc = () => setIsModalOpen(!isModalOpen)

    const renderGeneratedTemplate = () => {
        if (!user || !result?.fixure_recap_details) return;

        switch (user.orgId) {
            case 'christiania':
                return getFilledChristianiaVITemplate({
                    formData: formData,
                    cpDate: cpDateTemplate,
                    charterer: chartererTemplate,
                    cargo: cargoDescriptionTemplate,
                    laycan: laycanTemplate,
                    speed: speedTemplate,
                    cleaning: cleaningTemplate,
                    heating: heatingTemplate,
                    segregation: segregationTemplate,
                    loadingAgents: loadingAgentsTemplate,
                    loadAgentEmail: loadAgentEmailTemplate,
                    dischargeAgents: dischargeAgentsTemplate,
                    dischargeAgentEmail: dischargeAgentEmailTemplate,
                    portOfLoading: portLoadingTemplate,
                    portOfDischarge: portDischargeTemplate,
                    previousPort: previousPortTemplate,
                    broker: brokersTemplate,
                    brokersEmail: brokersEmailTemplate,
                    missingValue: missingValueTemplate,
                })
            case 'centurion':
                return getFilledCenturionVITemplate({
                    formData: formData,
                    vessel: vesselTemplate,
                    cpDate: cpDateTemplate,
                    charterer: chartererTemplate,
                    cargo: cargoDescriptionTemplate,
                    laycan: laycanTemplate,
                    speed: speedTemplate,
                    loadingAgents: loadingAgentsTemplate,
                    loadAgentEmail: loadAgentEmailTemplate,
                    dischargeAgents: dischargeAgentsTemplate,
                    dischargeAgentEmail: dischargeAgentEmailTemplate,
                    dischargeRate: dischargeRateTemplate,
                    portOfLoading: portLoadingTemplate,
                    portOfDischarge: portDischargeTemplate,
                    brokersEmail: brokersEmailTemplate,
                    missingValue: missingValueTemplate,
                })
            case 'navigator':
                // Add logic for 'navigator' if needed
                break;
            case 'falcon':
                return getFilledFalconVITemplate({
                    formData: formData,
                    cargo: cargoDescriptionTemplate,
                    cpDate: cpDateTemplate,
                    dischargeAgents: dischargeAgentsTemplate,
                    dischargeAgentEmail: dischargeAgentEmailTemplate,
                    laycan: laycanTemplate,
                    loadAgentEmail: loadAgentEmailTemplate,
                    loadingAgents: loadingAgentsTemplate,
                    portOfDischarge: portDischargeTemplate,
                    portOfLoading: portLoadingTemplate,
                    speed: speedTemplate,
                    missingValue: missingValueTemplate,
                })
            case 'internal':
                return getFilledCenturionVITemplate({
                    formData: formData,
                    cpDate: cpDateTemplate,
                    charterer: chartererTemplate,
                    cargo: cargoDescriptionTemplate,
                    laycan: laycanTemplate,
                    speed: speedTemplate,
                    cleaning: cleaningTemplate,
                    heating: heatingTemplate,
                    segregation: segregationTemplate,
                    loadingAgents: loadingAgentsTemplate,
                    loadAgentEmail: loadAgentEmailTemplate,
                    dischargeAgents: dischargeAgentsTemplate,
                    dischargeAgentEmail: dischargeAgentEmailTemplate,
                    portOfLoading: portLoadingTemplate,
                    portOfDischarge: portDischargeTemplate,
                    previousPort: previousPortTemplate,
                    broker: brokersTemplate,
                    brokersEmail: brokersEmailTemplate,
                    missingValue: missingValueTemplate,
                })
            default:
                break;
        }
    }

    return (
        <>
            <Box p={3}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsModalOpen(true)}
                    style={{ marginTop: "16px" }}
                >
                    See Voyage Instructions
                </Button>

            </Box>
            <Modal open={isModalOpen} onClose={onCloseFunc}>
                <Box sx={modalStyle} >
                    {renderGeneratedTemplate()}
                    <Box sx={{ marginTop: -4 }}>
                        {/* <Button onClick={() => { handleSaveAsPDF(formData) }}>Save as PDF</Button> */}
                        <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
                        <Button onClick={() => { handleSaveAsWord(formData, user.orgId) }}>Save as Word</Button>
                        <Button onClick={onCloseFunc}>Close</Button>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};

export default Recap2InstructionsResult;
