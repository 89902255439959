import React, { createContext, useContext, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProviderComponent = ({ children }) => {
  ThemeProviderComponent.propTypes = {
    children: PropTypes.any,
  };

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme === "true";
  });

  const toggleTheme = () => {
    setIsDarkMode((prev) => {
      const newMode = !prev;
      localStorage.setItem("isDarkMode", newMode);
      document.body.setAttribute("data-theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  const darkTheme = {
    palette: {
      mode: "dark",
      background: {
        default: "#17191E", // App background
        level1: "#24272F", // Background level 1
        level2: "#1A1C21", // Background level 2
        hover: "#111316",
        navigation: "#0E1012", // Navigation background
        navigationHover: "#1E1E25", // Hover background for navigation
      },
      primary: {
        main: "#2D6BCB", // Blue 4
        light: "#92BDFF", // Blue 2
        dark: "#1A3664", // Blue 6
        contrastText: "#FFFFFF", // High contrast text
      },
      secondary: {
        main: "#31BAAE", // Secondary 2
        dark: "#026E78", // Secondary 1
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#FFFFFF", // High contrast text
        secondary: "#B4B9C3", // Low contrast text
      },
      gradients: {
        darkBlue: "radial-gradient(144.32% 249.37% at 17.82% 31.06%, #000 0%, #1A3968 100%)",
        buttons: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)",
        sidebar: "linear-gradient(50deg, #3D86F6 0.05%, #0A2857 89.99%)",
        terniaryButton: "linear-gradient(305deg, #002C43 -12.67%, #31BAAE 94.03%)",
        ai: "linear-gradient(292deg, #3D86F6 26.96%, #31BAAE 61.92%)",
      },
      semantic: {
        success: "#00CC9B",
        warning: "#FF8B00",
        error: "#DC3058",
        info: "#1668BD",
      },
      additional: {
        purple7: "#7B57E0",
        purple8: "#6246B3",
        orange7: "#F9837C",
        orange8: "#C76963",
        mint7: "#50C099",
        mint8: "#30735C",
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
      h4: {
        fontWeight: 600,
      },
      body1: {
        color: "#FFFFFF",
      },
      body2: {
        color: "#B4B9C3",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#1A1C21", // Background for TextField
            color: "#FFFFFF", // Text color for TextField
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            background: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)", // Gradient buttons
            color: "#FFFFFF",
          },
        },
      },
    },
  };

  const lightTheme = {
    palette: {
      mode: "light",
      // Add light theme colors here following the same structure.
      primary: {
        main: "#1976D2",
      },
      secondary: {
        main: "#D81B60",
      },
      background: {
        default: "#F5F5F5",
        level1: "#FFFFFF",
        level2: "#EAEAEA",
        navigation: "#FFFFFF",
        navigationHover: "#F0F0F0",
      },
      text: {
        primary: "#000000",
        secondary: "#444444",
      },
      gradients: {
        darkBlue: "radial-gradient(144.32% 249.37% at 17.82% 31.06%, #000 0%, #1A3968 100%)",
        buttons: "linear-gradient(296deg, #01060D -35.01%, #3D86F6 70.39%)",
        sidebar: "linear-gradient(50deg, #3D86F6 0.05%, #0A2857 89.99%)",
        terniaryButton: "linear-gradient(305deg, #002C43 -12.67%, #31BAAE 94.03%)",
        ai: "linear-gradient(292deg, #3D86F6 26.96%, #31BAAE 61.92%)",
      },
      semantic: {
        success: "#00C853",
        warning: "#FF6D00",
        error: "#D32F2F",
        info: "#0288D1",
      },
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
      h4: {
        fontWeight: 600,
      },
    },
  };

  const theme = createTheme(isDarkMode ? darkTheme : lightTheme);

  useEffect(() => {
    document.body.setAttribute("data-theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
