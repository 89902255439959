export const approvedUsers = [
  // Shipster Internal
  { username: 'shipster', password: 'rules', organisation: 'internal', fullName: 'Shipster Internal', isAdmin: true },
  { username: 'admin', password: 'admin123', organisation: 'internal', fullName: 'Admin Internal', isAdmin: true },

  // Centurion 
  // Singapore
  { username: 'xyu@centurionbulk.com', password: 'PSyELChobv', organisation: 'centurion', fullName: 'Xin Yu', isAdmin: false },
  { username: 'kg@centurionbulk.com', password: 'IBQ0fkn9h9', organisation: 'centurion', fullName: 'Ken Gu', isAdmin: false },
  { username: 'sko@centurionbulk.com', password: 'LueatIQUOu', organisation: 'centurion', fullName: 'Satish Kotakonda', isAdmin: false },
  { username: 'jge@centurionbulk.com', password: 'tCNdr0CXoi', organisation: 'centurion', fullName: 'James Ge', isAdmin: false },
  { username: 'dn@centurionbulk.com', password: 'tGANTrEaTO', organisation: 'centurion', fullName: 'Dorte Nielsen', isAdmin: false },
  //CPH
  { username: 'jha@integritybulk.com', password: '26H9bVZZbz', organisation: 'centurion', fullName: 'Jesper Hansen', isAdmin: false },
  { username: 'ine@centurionbulk.com', password: 'jQCX3Mv2VT', organisation: 'centurion', fullName: 'Inga Ellekjaer', isAdmin: false },
  { username: 'lro@centurionbulk.com', password: 'radiOnAlHE', organisation: 'centurion', fullName: 'Laura Rosholm', isAdmin: false },
  { username: 'eyj@centurionbulk.com', password: 'x4I1jQE9ZGn', organisation: 'centurion', fullName: 'Emil Ylikulju Jensen', isAdmin: false },
  { username: 'jmn@centurionbulk.com', password: 'IWGhBGn2B1', organisation: 'centurion', fullName: 'Jesper Meincke Nielsen', isAdmin: false },
  { username: 'adb@centurionbulk.com', password: 'EVsNfEy5w0', organisation: 'centurion', fullName: 'Anders Damgaard Busk', isAdmin: false },
  //Monaco
  { username: 'mcu@centurionbulk.com', password: '4rSDsUN447', organisation: 'centurion', fullName: 'Morgane Cuillerier', isAdmin: false },
  { username: 'kni@centurionbulk.com', password: 'iNErbaCYa', organisation: 'centurion', fullName: 'Kristian Nielsen', isAdmin: false },
  //Peru
  { username: 'lux@centurionbulk.com', password: '8rh5Cozj2odT', fullName: 'Lucas', organisation: 'centurion', isAdmin: false },
  { username: 'vca@centurionbulk.com', password: 'D21YoC5LdlfQ', fullName: 'Valerie', organisation: 'centurion', isAdmin: false },
  {username: 'lxm@centurionbulk.com', password: 'heasIverSe', fullName: 'Lucas Xavier Meneses', organisation: 'centurion', isAdmin: false}
,  //Navigator
  { username: 'soren.vilhelmsen@navigatorgas.com', password: '1ap6!SZwJv', organisation: 'navigator', fullName: 'Soren Vilhelmsen', isAdmin: false },
  { username: 'hanna.houareau@navigatorgas.com', password: 'Mc00g&bNrj', organisation: 'navigator', fullName: 'Hanna Houareau', isAdmin: false },
  { username: 'adam.scott@navigatorgas.com', password: '@ebRUtP6x%', organisation: 'navigator', fullName: 'Adam Scott', isAdmin: false },
  { username: 'david.edwards@navigatorgas.com', password: '8326!@#$%', organisation: 'navigator', fullName: 'David Edwards', isAdmin: false },
  { username: 'kenneth.houmann@navigatorgas.com', password: 'tP6xMc00g', organisation: 'navigator', fullName: 'Kenneth Houmann', isAdmin: false },
  { username: 'jesper.resting@navigatorgas.com', password: 'Mc00p6!SZw', organisation: 'navigator', fullName: 'Jesper Resting', isAdmin: false },
  { username: 'gosia.raboteau@navigatorgas.com', password: 'Ld3sp8%Vxw', organisation: 'navigator', fullName: 'Gosia Raboteau', isAdmin: false },
  //Christianian
  { username: 'aup@christianiashipping.com', password: 'Pn1W2EA60V', organisation: 'christiania', fullName: 'Aurélien Perdiguero', isAdmin: false },
  //Falcon
  { username: 'ops@falcon-maritime.com', password: '7QI67yUwR8', organisation: 'falcon', fullName: 'Mads Dinesen Nielsen', isAdmin: false },
  //Lauritzen
  { username: 'Lauritzen', password: '1aE48GIusB', organisation: 'lauritzen', fullName: 'Lauritzen', isAdmin: false },
];

export const requesterCompanyOptions = [
  { label: "Shipster", organisation: 'internal', address: 'c/o Valcon, Meldahlsgade 5, 1613 København, Denmark' },
  { label: "Centurion Bulk Pte. Ltd", organisation: 'centurion', address: '80 Robinson Road #14-02, Singapore 068898' },
  { label: "Centurion MPP Pte. Ltd", organisation: 'centurion', address: '80 Robinson Road #14-02, Singapore 068898' },
  { label: "Navigator Gas", organisation: 'navigator', address: 'Verde, 10 Bressenden Place, London, SW1E 5DH, UK.' },
  { label: "Christiania", organisation: 'christiania', address: '' },
  { label: "Falcon", organisation: 'falcon', address: '' },
  { label: "Lauritzen", organisation: 'lauritzen', address: '' },
];

export const letterheadOptions = [
  { label: "Shipster Internal", path: "/assets/shipster-letterhead.png", organisation: 'internal' },
  { label: "Centurion Bulk Pte. Ltd", path: "/assets/centurionLetterhead.png", organisation: 'centurion' },
  { label: "Centurion MPP Pte. Ltd", path: "/assets/centurionMPP.png", organisation: 'centurion' },
  { label: "Navigator Gas", path: "/assets/navigatorLetterhead.png", organisation: 'navigator' },
  { label: "Christiania", organisation: 'christiania', path: '/assets/empty.png' },
  { label: "Falcon", organisation: 'falcon', path: '/assets/empty.png' },
  { label: "Lauritzen", organisation: 'lauritzen', path: '/assets/empty.png' },
];


