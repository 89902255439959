import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import mammoth from "mammoth";
import * as XLSX from "xlsx";
import PropTypes from 'prop-types';

const PDFViewer = ({ file }) => {
  PDFViewer.propTypes = {
    file: PropTypes.oneOfType([
        PropTypes.object, // For objects like { url: '...' }
        PropTypes.array, // For file paths or URLs as strings
    ]).isRequired,
};
  const fileArray = useMemo(() => {
    if (!file) return [];
    return Array.isArray(file) ? file : [file];
  }, [file]);

  const [docxContent, setDocxContent] = useState({});
  const [excelData, setExcelData] = useState({});

  useEffect(() => {
    const convertFiles = async () => {
      const newDocxContent = {};
      const newExcelData = {};

      for (let i = 0; i < fileArray.length; i++) {
        const file = fileArray[i];

        // Convert DOCX to HTML
        if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          const arrayBuffer = await file.arrayBuffer();
          const { value } = await mammoth.convertToHtml({ arrayBuffer });
          newDocxContent[i] = value;
        }

        // Convert Excel to JSON
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel") {
          const arrayBuffer = await file.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          newExcelData[i] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        }
      }

      setDocxContent(newDocxContent);
      setExcelData(newExcelData);
    };

    if (fileArray.length > 0) convertFiles();
  }, [fileArray]);

  return (
    <Box>
      {fileArray.length > 0 ? (
        fileArray.map((file, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Document {index + 1} - {file.name}
            </Typography>
            {file.type === "application/pdf" ? (
              <iframe
                title={`PDF Viewer ${index + 1}`}
                src={URL.createObjectURL(file)}
                width="100%"
                height="500px"
              />
            ) : file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <div
                dangerouslySetInnerHTML={{ __html: docxContent[index] || "Loading..." }}
                style={{ border: "1px solid #ddd", padding: "16px", height: "500px", overflowY: "auto" }}
              />
            ) : file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel" ? (
              <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {excelData[index]?.[0]?.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell || "Header"}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {excelData[index]?.slice(1).map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <TableCell key={cellIndex}>{cell}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography color="error">Unsupported file type</Typography>
            )}
          </Box>
        ))
      ) : (
        <Typography>No document selected</Typography>
      )}
    </Box>
  );
};

export default PDFViewer;
